$('.js-burger').click(function(){
	$('.js-nav').toggleClass('c-nav--open');
});

$('.js-tile__header').responsiveEqualHeightGrid();
$('.js-tile__body').responsiveEqualHeightGrid();

$('.js-member').responsiveEqualHeightGrid();
$('.js-newsletter').responsiveEqualHeightGrid();


$('.js-card').responsiveEqualHeightGrid();
//$('.js-work-description').responsiveEqualHeightGrid();

$('document').ready(function () {
	/*var trigger = $('#js-hamburger');

	trigger.click(function(){
		trigger.toggleClass('is-open');
		trigger.toggleClass('is-closed');
		$('.js-nav').toggleClass('c-nav--open');
	});*/

	//set the height of mobile draw



	$('.js-hamburger').click(function(){
		var mobileHeight = $('.js-header').outerHeight() + $('.js-banner').outerHeight();

		//Only restrict the height when there is a header area
		if (mobileHeight > 100) {
			$('.js-mobile').height(mobileHeight);
		}
		
		$('.js-mobile').toggleClass('is-open');

		$(this).toggleClass('is-open');
	});


});


